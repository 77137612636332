import Link from 'next/link'
import { useSearchContentsQuery } from 'src/graphql/generated/hooks'

import { ContentWriter } from '../ContentWriter/ContentWriter'
import { HtmlContentViewer } from '../global/HtmlContentViewer/HtmlContentViewer'

export const LastFromVendorCheatsheet: React.FC = () => {
  const { data } = useSearchContentsQuery({
    variables: {
      /* query: {
        slug: 'last-from-vendor-cheatsheet',
        contentType: 'Blog',
        limit: 3,
        sort: 'createdAt:desc',
      }, */
      query: {
        blogTaxonomyIds: '6',
        contentType: 'Blog',
        limit: 3,
        sort: 'Chronologically',
      },
    },
  })

  return (
    <div className="w-full px-3 large-lg:px-0 min-h-[20.75rem] bg-clouds pb-6">
      <div className="!max-w-[1100px] flex flex-col justify-start mx-auto">
        <span className="font-secondary font-bold text-[1.5rem] text-black text-center md:text-left md:mr-10">
          Latest from the Vendor Cheatsheet
        </span>
        <div className="grid md:grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-5 mt-[1rem]">
          {data?.contentResults.data?.contents?.map((content) => (
            <Link
              key={content?.id}
              href={`/contents/${content?.slug}`}
              className="h-[14.5rem] w-full bg-white rounded-xl px-8 py-6 border border-solid border-inherit "
            >
              <div className="flex flex-col h-[10rem]">
                <p className="text-textdark font-bold text-[1.1rem] mt-1 h-[3.1rem] overflow-hidden leading-6">
                  {content?.title}
                </p>
                <div className="h-[4.8rem] overflow-hidden -mt-1">
                  <HtmlContentViewer
                    contentClassName="text-[#707070] text-[.rem] font-primary"
                    html={content?.snippet || ''}
                  />
                </div>
              </div>
              {content?.writer && <ContentWriter writer={content?.writer} />}
            </Link>
          ))}
        </div>
        <Link
          href={'/blogs/the-vendor-cheatsheet/'}
          className="text-center font-secondary font-bold text-[1rem] text-textdark underline cursor-pointer"
        >
          View All
        </Link>
      </div>
    </div>
  )
}
